/* ----------------------------------------
    Mobile Menu
---------------------------------------- */

export class MobileMenu {

    constructor() {
        this._menu = document.querySelector('.menu');
        if (!this._menu) return;

        this._nav = document.querySelector('.nav');
        this._toggle = document.querySelector('.nav__menu');

        this._event();
        this.active = false;
    }

    /* ----------------------------------------
        Set State
    ---------------------------------------- */

    get active() {
        return this._active;
    }
    set active(value) {
        this._active = value;

        document.body.style.overflow = value ? 'hidden' : '';
        this._nav.classList.toggle('is-active', value);
        this._toggle.classList.toggle('is-active', value);
        this._toggle.textContent = value ? 'Sluiten' : 'Menu'

        document[value ? 'addEventListener' : 'removeEventListener']('keydown', (e) => {
            e = e || window.e;

            if (e.isComposing || e.keyCode === 27) {
                this.active = false;
            }
        })
    }

    /* ----------------------------------------
        Events
    ---------------------------------------- */

    _event() {
        this._toggle.addEventListener('click', (e) => {
            this.active = !this.active;
            document.dispatchEvent(new MouseEvent('menu:toggle', e));
            document.dispatchEvent(new MouseEvent(`menu:${this.active ? 'open' : 'close' }`, e));
        });
    }

}
