/** ----------------------------------------
    Node Modules
 ---------------------------------------- */

import anime from 'animejs';
import { throttle } from 'lodash';

/** ----------------------------------------
    Utilities
 ---------------------------------------- */

import { loader } from '@js/util/loader';

/** ----------------------------------------
    Mouse Visibility
 ---------------------------------------- */

const fadeIn = el => {
    anime.remove(el)
    anime({
        targets: el,
        opacity: [0, 1],
        duration: 250,
        easing: 'easeOutQuad'
    });
};

const fadeOut = el => {
    anime.remove(el)
    anime({
        targets: el,
        opacity: [1, 0],
        duration: 175,
        easing: 'easeOutQuad'
    });
};

/** ----------------------------------------
    Revael Image
 ---------------------------------------- */

let animating = false;

const imageIn = el => {
    anime({
        targets: el,
        scale: [0.6, 1],
        opacity: [0, 1],
        duration: 225,
        easing: 'easeOutQuad',
        begin: () => animating = true,
        complete: () => animating = false
    });
};

/** ----------------------------------------
    Makers
 ---------------------------------------- */

export class Makers {

    constructor(el) {
        if(!this.is_touch_enabled()) {
            this.el = el;
            this.els = { el };
            this.makerSrcImg ='';
            this.itemHeight = 0;
            this.init();
        }
    }

    /** ----------------------------------------
         Events
     ---------------------------------------- */
     is_touch_enabled() { 
        return ( 'ontouchstart' in window ) ||  
               ( navigator.maxTouchPoints > 0 ) || 
               ( navigator.msMaxTouchPoints > 0 ); 
    } 

    _mouse() {
        const event = e => {
            this._setImage(e.target).then(() => {
                this._updateMousePosition({ 
                    x: e.clientX - this.els.mouse.clientWidth * .5, 
                    y: e.clientY - this.els.mouse.clientHeight * .5 
                });
            })
        };

        this.els.grid.addEventListener('mousemove', throttle(event, 5));
    }

    /** ----------------------------------------
         Set Mouse
     ---------------------------------------- */

    _updateMousePosition({x, y}) {
        this.els.mouse.style.transform = `translate(${x}px, ${y}px)`
    }


    _setImage(target) {
        let src = target.dataset.src || target.parentNode.dataset.src
        if(!src || src === this.makerSrcImg) return Promise.resolve();
        this.makerSrcImg = src;
        this.els.image.src = this.makerSrcImg;
        return new Promise((resolve, reject) => {
            this.els.image.addEventListener('load', (event) => {
                !animating && imageIn(this.els.image);
                return resolve();
            })
        });
    }

    /** ----------------------------------------
        Hover
     ---------------------------------------- */

    _hover() {
        this.els.grid.querySelectorAll('.makers__name').forEach((hoverEl) => {
            hoverEl.addEventListener('mouseenter', (e) => {
                this.active = true;
                this._changeHover();
            });
            
            hoverEl.addEventListener('mouseleave', (e) => {
                this.active = false;
                this._changeHover();
            });
        });
    }
    

    _changeHover() {
        this.active ? fadeIn(this.els.mouse) : fadeOut(this.els.mouse);
    }
    /** ----------------------------------------
        Scroll
     ---------------------------------------- */
    _scroll() {
        document.addEventListener('scroll', () => {
            if(!this.active) return;
            this.els.image.src = '';
            this.active = false;
            this._changeHover();
        })
    }

    /** ----------------------------------------
         Preload Images
     ---------------------------------------- */

    _loader() {
        const images = [...this.els.items].map(item => item.dataset.src);
        loader(images);
    }

    /** ----------------------------------------
         Hide extras
     ---------------------------------------- */

     _hide() {
        const max = 6;
        if (this.els.items.length >= max) {
            this.els['more'].classList.add('is-active');

            this.els.items.forEach((element, i) => {
                i >= max -1 && element.classList.add('is-hidden');
            });
        }
    }

    /** ----------------------------------------
        Show more
     ---------------------------------------- */

    _showMore() {
        let $this = this;
        $this.els['more'].addEventListener('click', function () {
            $this.els.items.forEach((element, i) => {
                element.classList.remove('is-hidden');
            });

            $this.els['more'].classList.remove('is-active');
        }, false);
    }

    /** ----------------------------------------
        Init
     ---------------------------------------- */

    init() {
        if(this.el) {
            this.els['grid'] = this.el.querySelector('.makers__grid');
            this.els['items'] = this.el.querySelectorAll('.makers__item');
            this.els['more'] = this.el.querySelector('.makers__more');

            this.els['mouse'] = this.el.querySelector('.makers__mouse');
            this.els['image'] = this.el.querySelector('.makers__image');

            this._loader();
            this._hover();
            this._scroll();
            this._mouse();
            this._hide();
            this._showMore();
        }
    }
}
