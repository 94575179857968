/** ----------------------------------------
    Style
 ---------------------------------------- */

/**
 * This is the primary class that takes
 * the settings and templates configured
 * within the main JS file. This class
 * extends all methods from the content
 * class and will use these to create
 * template parts from the provided
 * data configured within the stylesheet.
 */

import { StyleData } from './StyleData.js';
import { StyleContent } from './StyleContent.js';
import { StyleHelper } from './StyleHelper.js';

export class Style extends StyleContent {

    /**
     * @info            takes info arguments
     * @settings        takes settings arguments
     * @show            boolean to display style guide
     * @data            takes all data from StyleData
     * @helper          takes all helper methods
     * @init            calls the init method
     */

    constructor(info, settings, show = true) {
        super();
        this.info = info;
        this.settings = settings;
        this.show = show;
        this.data = new StyleData();
        this.helper = new StyleHelper();
        this.init();
    }

    /** ----------------------------------------
        Add Template
     ---------------------------------------- */

    /**
     * This function will set the template
     * based on a declared template type and
     * appends the template to the main tag
     * within the body of the document.
     */

    addTemplate(name, info, data = null) {
        let html = '';

        switch (name) {

            case 'title':
                html = this.setTitle(info);
                break;

            case 'separator':
                html = this.setSeparator();
                break;

            case 'breakpoint':
            case 'breakpoints':
                html = this.setBreakpoints(info, data);
                break;

            case 'grid':
                html = this.setGrid();
                break;

            case 'fonts':
            case 'font-face':
                html = this.setFontFace(info, data);
                break;

            case 'letter':
            case 'letters':
                html = this.setLetters(info, data);
                break;

            case 'color':
            case 'colors':
                html = this.setColors(info, data);
                break;

            case 'combi':
            case 'combinations':
                html = this.setCombinations(info, data);
                break;

            case 'branding':
                html = this.setBranding(info, data);
                break;

            case 'heading':
            case 'headings':
                html = this.setHeadings(info, data);
                break;

            case 'text':
                html= this.setText(info);
                break;

            case 'article':
            case 'content':
                html= this.setArticle(info);
                break;

            case 'font-style':
            case 'text-style':
                html= this.setFontStyles();
                break;

            case 'anchors':
            case 'links':
                html= this.setLinks(info);
                break;

            case 'tooltip':
                html= this.setToolTip(info);
                break;

            case 'forms':
            case 'form-general':
            case 'form-label':
                html= this.setFormGeneral(info);
                break;

            case 'form-placeholder':
                html= this.setFormPlaceholder(info);
                break;

            case 'form-options':
                html = this.setFormOptions(info);
                break;

            case 'custom-form-options':
                html = this.setCustomFormOptions(info);
                break;

            case 'list':
            case 'ul-list':
                html = this.setUlList(info);
                break;

            case 'ol-list':
                html = this.setOlList(info);
                break;

            case 'dd-list':
                html = this.setDdList(info);
                break;

            case 'table-full':
                html = this.setTableFull(info);
                break;

            case 'table-plain':
                html = this.setTablePlain(info);
                break;

            case 'button-color':
            case 'button-colors':
                html = this.setButtonColors(info, data);
                break;

            case 'button-size':
            case 'button-sizes':
                html = this.setButtonSizes(info, data);
                break;

            case 'icons':
                html = this.setIcons(info, data);
                break;

            default:
                html = `<div class="error">¯\\_(ツ)_/¯ Sorry, no related template found.</div>`;
        }

        this.appendToBody(html);
    }

    /** ----------------------------------------
        Copy Template
     ---------------------------------------- */

    /**
     * Copy the values directly from the template
     * by clicking on a selected part.
     */

    addToClipboard(text) {
        let temp = document.createElement('input');
        document.body.appendChild(temp);
        temp.setAttribute('value', text);
        temp.select();
        document.execCommand('copy');
        document.body.removeChild(temp);
    }

    copyTemplate() {
        const template = document.querySelectorAll('.js-copy-template');
        for (let copy of template) {
            copy.addEventListener('click', (e) => {
                let info = copy.dataset.copy;
                this.addToClipboard(info);
            });
        }
    }

    /** ----------------------------------------
        Log
     ---------------------------------------- */

    log() {
        console.log(this.data);
    }

    /** ----------------------------------------
        Init
     ---------------------------------------- */

    /**
     * This function is called within the
     * constructor and will initiate once the
     * class is loaded.
     */

    init() {
        if(this.show) {
            this.setContent();
            this.configTemplates();
            this.copyTemplate();
        }
    }

}