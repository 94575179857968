/** ----------------------------------------
    Node Modules
 ---------------------------------------- */

import anime from 'animejs';

/** ----------------------------------------
    Utilties
 ---------------------------------------- */

import style from '@js/util/style';

/** ----------------------------------------
    More
 ---------------------------------------- */

export class More {

    constructor(options) {
        this.active = false;
        this.content = {};
        this.els = {};
        // this is the collapsed height
        this.height = options.height || 140;
        // if height is higher than thresh, it will be collapsed
        this.thresh = options.thresh || 500;
        this.options = options;
        this.originalHeight = 0;
        this.wrapper = options.wrapper;

        this.init();
    }

    /** ----------------------------------------
        Animate
     ---------------------------------------- */

    _toggle(bool) {
        this.active = bool;
        this.els.button.textContent = this.content[(!bool).toString()];
        document.dispatchEvent(new Event(`more:${this.active}`))
    }

    /** ----------------------------------------
        Animate
     ---------------------------------------- */

    _animate(e) {
        const holder = this.els.more;
        
        const open = () => anime({
            targets: holder,
            height: [this.height, this.originalHeight],
            easing: 'easeOutQuad',
            duration: this.originalHeight - this.height,
            begin: () => {
                this.scrollPos = window.scrollY;
            },
            complete: () => this._toggle(true)
        });

        const close = () => anime({
            targets: holder,
            height: [this.originalHeight, this.height],
            easing: 'easeOutQuad',
            duration: this.originalHeight - this.height,
            begin: () => {
                window.scrollTo({top: this.scrollPos})
            },
            complete: () => this._toggle(false)
        });

        !this.active ? open() : close();
    }

    /** ----------------------------------------
         Set Content
     ---------------------------------------- */

    _setContent() {
        const defaultContent = {
            'true': this.els.button.textContent,
            'false': 'Minder'
        };

        const setContent = content => {
            this.content['true'] = content.more;
            this.content['false'] = content.less;
        };

        this.options.content ? setContent(this.options.content) : setContent(defaultContent);
    }

    /** ----------------------------------------
        Set Height
     ---------------------------------------- */

    _setHeight() {
        this.originalHeight = this.els.more.clientHeight;

        style(this.els.more, {
            height: this.height + 'px',
            position: 'relative',
            overflow: 'hidden'
        });
    }

    /** ----------------------------------------
        Set Event
     ---------------------------------------- */

    _setEvent() {
        this.els.button.addEventListener('click', this._animate.bind(this));
    }

    /** ----------------------------------------
        Init
     ---------------------------------------- */

    init() {
        if(document.querySelector(this.wrapper)) {
            this.els['wrapper'] = document.querySelector(this.wrapper);
            this.els['more'] = this.els.wrapper.querySelector('.more');
            this.els['button'] = this.els.wrapper.querySelector('.more__button');
            
            if (!this.els.button) return;
            this.originalHeight = this.els.more.clientHeight;

            if (this.originalHeight > this.thresh) {
                this._setContent();
                this._setHeight();
                this._setEvent();
            } else {
                this.els.button.classList.add('more--is-hidden');
            }
        }
    }
}