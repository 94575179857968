/** ----------------------------------------
     Style Data
 ---------------------------------------- */

/**
 * The core function of this class is to
 * set or convert all the data from the
 * SASS stylesheet within an object or
 * an array of data.
 */

const styles = require('../../scss/style.scss');
const dJSON = require('dirty-json');

/** custom helpers */
import { StyleHelper } from './StyleHelper.js';

export class StyleData {

    /**
     * @styles          takes all exported SASS variables
     * @colors          empty object stores colors
     * @combinations    empty object stores color combinations
     * @branding        empty object stores color branding
     * @font_face       empty array stores font-face values
     * @font_style      empty object store font styles
     * @helper          takes all helper methods
     * @init            calls the init method
     */

    constructor() {
        this.styles = styles;
        this.breakpoints = {};
        this.colors = {};
        this.combinations = {};
        this.branding = {};
        this.font_face = [];
        this.font_style = {};
        this.buttons = {};
        this.icons = [];
        this.helper = new StyleHelper();
        this.init();
    }

    /** ----------------------------------------
        Set Breakpoints
     ---------------------------------------- */

    /**
     * This function will parse the data from
     * $global-breakpoints variable and add it
     * to an object that contains all values.
     *
     * @returns             { object }
     */

    setBreakpoints() {
        const breakpoints = this.styles.breakpoints;

        if(!this.helper.stringIsEmpty(breakpoints)) {
            let convert = breakpoints.replace(/['"() ]+/g, '');
            let array = convert.split(',');
            let object = this.helper.arrayToObject(array);
            this.breakpoints = object;
        }
    }

    /** ----------------------------------------
        Set Colors
     ---------------------------------------- */

    /**
     * This function will parse the data from
     * $global-colors variable into a object
     * and store it inside the constructor.
     *
     * @returns             { object }
     */

    setColors() {
        const colors = this.styles.colors;

        if(!this.helper.stringIsEmpty(colors)) {
            let convert = colors.replace(/['"() ]+/g, '');
            let array = convert.split(',');
            let object = this.helper.arrayToObject(array);
            this.colors = object;
        }
    }

    /** ----------------------------------------
        Set Combinations
     ---------------------------------------- */

    /**
     * This function will parse the data from
     * $global-combinations variable into a
     * object and store it inside the constructor.
     *
     * @returns             { object } parsed json
     */

    setCombinations() {
        let combinations = this.styles.combinations;

        if(!this.helper.stringIsEmpty(combinations)) {
            let convert = combinations
                .replace(/[(]+/g, '{')
                .replace(/[)]+/g, '}');

            let parsed = dJSON.parse(convert + '}');
            this.combinations = parsed;
        }
    }

    /** ----------------------------------------
        Set Branding
     ---------------------------------------- */

    /**
     * This function will collect the branding
     * colors from the $branding variable in the
     * SASS config file.
     *
     * @returns             { object } parsed json
     */

    setBranding() {
        let branding = this.styles.branding;

        if(!this.helper.stringIsEmpty(branding)) {
            let convert = branding
                .replace(/[(]+/g, '{')
                .replace(/[)]+/g, '}')
                .replace(/#[0-9a-f]{6}|#[0-9a-f]{3}/gi, function(hex){
                    return '"' + hex.toUpperCase() + '"';
                });

            let parsed = dJSON.parse(convert + '}');
            this.branding = parsed;
        }
    }

    /** ----------------------------------------
        Set Font Face
     ---------------------------------------- */

    /**
     * This function will parse the fonts that
     * are used within the SASS files from the
     * given variables.
     *
     * @returns             { object }
     */

    setFontFace() {
        this.font_face.default = [
            'Arial',
            'Bookman',
            'Comic Sans MS',
            'Courier',
            'Courier New',
            'Garamond',
            'Georgia',
            'Helvetica',
            'Impact',
            'Platino',
            'Times New Roman',
            'Times',
            'Trebuchet MS',
            'Verdana'
        ];

        let fonts = this.styles.font_face;
        let array = fonts.split(',');
        let sans_serif = this.helper.removeFromArray(array, 'sans-serif');
        let serif = this.helper.removeFromArray(sans_serif, 'serif');
        let reverse = serif.reverse();

        this.font_face.all = reverse;
        this.font_face.custom = this.helper.arrayDiff(this.font_face.all, this.font_face.default);
        this.font_face.fallbacks = this.helper.arrayDiff(this.font_face.all, this.font_face.custom);
    }

    /** ----------------------------------------
        Set Font Styles
     ---------------------------------------- */

    /**
     * This function will parse the fonts
     * styles that are used within in the
     * main configuration as the
     * $global-text variable.
     *
     * @returns             { object } parsed json
     */

    setFontStyle() {
        let font_style = this.styles.font_style;

        if(!this.helper.stringIsEmpty(font_style)) {
            let convert = font_style
                .replace(/(\d*\.?\d+)\s?(px|em|ex|%|in|cn|mm|pt|pc+)/g, '"$1$2"')
                .replace(/((?!\])"font-family(?!\[).):[^'].+?(?=, "(color|font|text+))/g, ' ')
                .replace(/[(]+/g, '{')
                .replace(/[)]+/g, '}');

            let parsed = dJSON.parse(convert + '}');
            this.font_style = parsed;
        }
    }

    /** ----------------------------------------
        Set Buttons
     ---------------------------------------- */

    /**
     * This function will parse the buttons that
     * are used within the SASS files from the
     * given variables. These have to be added
     * or extended manually within the
     * ui/buttons file.
     *
     * @returns             { object }
     */

    setButtons() {
        let button = this.styles.btn_name;

        if(button != 'null' && !this.helper.empty(button)) {
            let names = button.split(',');
            this.buttons.names = names;
        }

        let button_size = this.styles.btn_size;

        if(button_size != 'null' && !this.helper.empty(button)) {
            let sizes = button_size.split(',');
            this.buttons.size = sizes;
        }
    }

    /** ----------------------------------------
        Set Icons
     ---------------------------------------- */

    /**
     * This function will parse the data from
     * $global-icons variable into a array
     * and store it inside the constructor.
     *
     * @returns             { array }
     */

    setIcons() {
        const icons = this.styles.icons;

        if(!this.helper.stringIsEmpty(icons)) {
            this.icons = icons.split(',');
        }
    }

    /** ----------------------------------------
        Save to File
     ---------------------------------------- */

    /**
     * This function will store the data into
     * a text file so the data can be accessed
     * within the actual online website!
     */

    storeData() {
        // let jsonData = {
        //     'hi': 'sander'
        // };
        //
        // fs.writeFile('test.txt', jsonData, function(err) {
        //     if (err) {
        //         console.log(err);
        //     }
        // });
    }

    /** ----------------------------------------
        Init
     ---------------------------------------- */

    /**
     * This function is called within the
     * constructor and will initiate once the
     * class is loaded.
     */

    init() {
        this.setBreakpoints();
        this.setColors();
        this.setCombinations();
        this.setBranding();
        this.setFontFace();
        this.setFontStyle();
        this.setButtons();
        this.setIcons();
        this.storeData();
    }

}