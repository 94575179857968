/** ----------------------------------------
    FANCY ARROW
 ---------------------------------------- */
const FancyArrow = () => {
    let arrow = document.querySelector('.fancy-arrow');
    document.addEventListener('menu:open', init);
    document.addEventListener('menu:close', destroy);

    function init(e) { 
        arrow.classList.add('is-active');
        onMouseMove(e);
        document.addEventListener('mousemove', onMouseMove); 
    }
    function destroy() { 
        arrow.classList.remove('is-active');
        document.removeEventListener('mousemove', onMouseMove); 
    }
    
    const distance = (x1, y1, x2, y2) => {
        return Math.round(Math.sqrt( Math.pow((x1-x2), 2) + Math.pow((y1-y2), 2) ));
    }

    const degrees = (rad) => {
        var pi = Math.PI;
        return rad * (180/pi);
    }

    function moveArrow() {
        arrow.style.transform = `translate(${arrow.position.mx}px, ${arrow.position.my}px) rotate(${(arrow.position.rotation)}deg)`;
    }

    function onMouseMove(e) {
        arrow.position = calcPosition(e.clientX, e.clientY);
        requestAnimationFrame(moveArrow)
    };

    function calcPosition(x, y) {
        let mx = x - (arrow.clientWidth);
        let my = y - (arrow.clientHeight * .5);
        let screenCenter = { x: window.innerWidth/2, y: window.innerHeight/2 }
        let pointTo = { x: screenCenter.x, y: screenCenter.y }
        let hypotenuse = distance(pointTo.x, pointTo.y, mx, my);
        let adjacent = distance(pointTo.x, pointTo.y, mx, pointTo.y)
        let radians = Math.acos(adjacent / hypotenuse);
        let xhalf = pointTo.x < mx;
        let yhalf = pointTo.y < my
        let modifier = -180;
        let angle = Math.floor(degrees(radians));

        // ABOVE AND RIGHT OF POINT
        xhalf && !yhalf && (angle = angle * -1);
        // ABOVE AND LEFT OF POINT
        !xhalf && !yhalf && (modifier = 0);
        // BELOW AND LEFT OF POINT
        !xhalf && yhalf && (angle = angle * -1) && (modifier = 0);

        angle == 0 && !xhalf && (modifier = 0);

        return {
            mx,
            my,
            rotation: angle + modifier
        }
    };
}

export default FancyArrow;