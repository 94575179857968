/** ----------------------------------------
    Style Helper
 ---------------------------------------- */

/**
 * The methods within this class are used
 * within several classes to assist.
 */

export class StyleHelper {

    /** ----------------------------------------
        Convert HEX to RGBA
     ---------------------------------------- */

    /**
     * This function will convert a given
     * HEX number to a RGBA value.
     *
     * @param color         { number } hex value
     * @param alpha         { number } 0 to 1
     * @param return        { string } rgba value
     */

    convertHexToRgba(color, alpha = 1) {
        let c;

        if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(color)){
            c = color.substring(1).split('');
            if (c.length== 3) {
                c= [c[0], c[0], c[1], c[1], c[2], c[2]];
            }
            c = '0x'+c.join('');
            return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+',' + alpha + ')';
        }

        throw new Error('Dat is geen HEX!');
    }

    /** ----------------------------------------
        Random String
     ---------------------------------------- */

    /**
     * Generates a random string of characters
     * and numbers.
     *
     * @returns             { string }
     */

    randomString() {
        return Math.random().toString(36).substring(2, 15);
    }

    /** ----------------------------------------
        String Empty
     ---------------------------------------- */

    /**
     * This function will check if a given
     * string is empty.
     *
     * @param string        { string }
     * @returns             { boolean }
     */


    stringIsEmpty(string) {
        return (!string || 0 === string.length);
    }

    /** ----------------------------------------
        Convert Array
     ---------------------------------------- */

    /**
     * This function will take an array of
     * property value combinations and parse
     * the input into a object. This function
     * is primarily used for the setColors()
     * function within this class.
     *
     * @param array         { array } array to be parsed
     * @returns             { object } object based on array
     */

    arrayToObject(array) {
        let object = {};
        array.forEach(function(element) {
            var first = element.match(/(.*):/g).pop().replace(':', '');
            var last = element.match(/:(.*)/g).pop().replace(':', '');

            object[first] = last;
        });
        return object;
    }

    /** ----------------------------------------
        Compare and Remove
     ---------------------------------------- */

    /**
     * This function will take two arrays and
     * remove values that exist in both arrays.
     *
     * @param array1        { array }
     * @param array2        { array }
     * @returns             { array } new array
     */

    arrayDiff(array1, array2) {
        return array1.filter(function(elm) {
            return array2.indexOf(elm) === -1;
        });
    }

    /** ----------------------------------------
        Remove from Array
     ---------------------------------------- */

    /**
     * This function will take an array of values
     * and strip a given value from the array.
     *
     * @param array         { array }
     * @param remove        { string } value to be removed
     * @returns             { array } new array
     */

    removeFromArray(array, remove) {
        let newArray = [];

        for (let i = array.length - 1; i >= 0; i--) {
            let striped = array[i].replace( /\s/g, '');
            if (striped !== remove) {
                newArray.push(striped);
            }
        }

        return newArray;
    }

    /** ----------------------------------------
        String Not Empty
     ---------------------------------------- */

    /**
     * Checks whether a string has value.
     *
     * @param string        { string }
     * @returns             { boolean }
     */

    empty(string){
        return (!string || 0 === string.length);
    }

}