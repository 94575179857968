/** ----------------------------------------
    Helper
 ---------------------------------------- */

import * as dom from './dom';

/** ----------------------------------------
    Settings
 ---------------------------------------- */

let loaded = [];

/** ----------------------------------------
    Random ID
 ---------------------------------------- */

const randomId = () => {
    return Math.random().toString(36).replace(/[^a-z]+/g, '').substr(2, 10);
};

/** ----------------------------------------
    Create Holder
 ---------------------------------------- */

const imageHolder = () => {
    const holder = document.createElement('div');

    dom.style(holder, {
        width: '1px',
        height: '1px',
        background: 'orange',
        position: 'absolute',
        top: '-10px',
        left: '-10px',
        zIndex: 100000,
        overflow: 'hidden'
    });

    dom.attr(holder, {
        class: 'image__holder'
    });

    document.body.insertAdjacentHTML('beforeend', holder.outerHTML);
};

/** ----------------------------------------
    Remove Holder
 ---------------------------------------- */

const removeHolder = () => {
    const holder = document.querySelector('.image__holder');
    holder.remove();
};

/** ----------------------------------------
    Apply Image
 ---------------------------------------- */

const applyImage = img => {
    const holder = document.querySelector('.image__holder');
    const id = randomId();
    dom.attr(img, { id: id });
    holder.insertAdjacentHTML('beforeend', img.outerHTML);
    return id;
};

/** ----------------------------------------
    Retrieve Image
 ---------------------------------------- */

const retrieveImage = id => {
    const holder = document.querySelector('.image__holder');
    return holder.querySelector('#' + id);
};

/** ----------------------------------------
    Remove Image
 ---------------------------------------- */

const removeImage = id => {
    const image = retrieveImage(id);
    image.remove();
};

/** ----------------------------------------
    Load Image
 ---------------------------------------- */

const loadImage = path => {
    let id;

    return new Promise((resolve, reject) => {
        const img = new Image();

        img.onload = () => {
            const data = {
                id: id,
                src: img.src,
                el: img,
            };

            loaded.push(data);
            resolve(data);
        };

        img.onerror = () => {
            reject({
                id: id,
                img: img
            });
        };

        img.src = path;

        id = applyImage(img);
    })
};

/** ----------------------------------------
    Timer
 ---------------------------------------- */

const timer = () => {
    let start;
    let end;

    return {
        startTimer: () => start = new Date(),
        endTimer: () => end = new Date() - start,
        resetTimer: () => {
            start = null;
            end = null;
        }
    }
};

const time = timer();

/** ----------------------------------------
    Loader
 ---------------------------------------- */

const loader = (images, expected = 100) => {
    time.startTimer();

    imageHolder();

    const amount = images.length;

    return new Promise(resolve => {
        images.forEach(image => {
            const load = loadImage(image);

            load.then((data) => {
                const progress = loaded.length / amount * 100;

                progress >= expected && resolve({
                    images: loaded,
                    progress: {
                        percentage: progress,
                        amount: loaded.length,
                        total: amount,
                        time: time.endTimer()
                    }
                });

                removeImage(data.id);

                if(progress >= 100) resetLoader();
            });
        });
    });
};

/** ----------------------------------------
    Reset Loader
 ---------------------------------------- */

const resetLoader = () => {
    loaded = [];
    time.resetTimer();
    removeHolder();
};

/** ----------------------------------------
    Exports
 ---------------------------------------- */

export {
    loader
}