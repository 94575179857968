/** ----------------------------------------
    Style
 ---------------------------------------- */

const style = (el, style) => {
    el && Object.keys(style).map(key => {
        el.style[key] = style[key];
    });
};

/** ----------------------------------------
    Exports
 ---------------------------------------- */

export default style;