import $ from 'jquery';

/** ----------------------------------------
     Sticky
 ---------------------------------------- */

export class Sticky {

    constructor(wrapper, isTablet) {
        this.isTablet = isTablet;
        this.wrapper = document.querySelector(wrapper);
        this.textItems = document.querySelectorAll('.sticky__item');
        this.footer = document.querySelector('.footer');
        this.carousel = document.querySelector('.sticky__carousel');
        this.information = document.querySelector('.information');
        this.seeRoomsBtns = document.querySelectorAll('.sticky__img-button');
        this.rooms = document.querySelector('.rooms');
        this.textItemsPos = [];
        this.slider = $('.sticky__slides');
        this.init();
    }

    /** ----------------------------------------
        Scroll
     ---------------------------------------- */

    _scroll() {
        window.addEventListener('scroll', () => {
            this.slider.slick('slickGoTo', this._inView(window.pageYOffset))
            this._checkFixed(window.pageYOffset);
        });
    }

    /** ----------------------------------------
        Check fixed
     ---------------------------------------- */

    //  This checks whether the carousel or the slider should be
    // fixed or relative, depending on scroll position.

    _checkFixed(scrollTop) {
        const footerHeight = this.footer.offsetHeight;
        let docHeight = document.body.scrollHeight - window.innerHeight;
        let pageEnd = docHeight - footerHeight;
        let reachedEnd = scrollTop > pageEnd;

        // when reaching end of page, text carousel disappears and stops being fixed
        if (reachedEnd) {
            this.carousel.classList.remove('fixed');
        } else {
            this.carousel.classList.add('fixed');
        }
        
        // when reaching end of wrapper, oaimage slider stops being fixed
        if(scrollTop > this.wrapper.offsetHeight + this.wrapper.offsetTop - window.innerHeight) {
            this.slider.removeClass('fixed');
        } else {
            this.slider.addClass('fixed');
        }
        
    }
    
    /** ----------------------------------------
        Get all text item positions
     ---------------------------------------- */

    _getItemPos() {
        this.textItemsPos = [];
        this.textItems.forEach(el => {
            this.textItemsPos.push(this._getOffset(el).top)
        })
    }

    /** ----------------------------------------
        Get element position
     ---------------------------------------- */

    _getOffset(el) {
        const rect = el.getBoundingClientRect();
        return {
            left: rect.left + window.scrollX,
            top: rect.top + window.scrollY
        };
    }

    /** ----------------------------------------
        View
     ---------------------------------------- */

    _inView(scrollTop) {
        const thresh = window.innerHeight / 2;
        let active = 0;
        for (let i = 0; i < this.textItemsPos.length; i++) {
            if ((scrollTop + thresh) > this.textItemsPos[i]) {
                active = i;
            }
        }

        return active;
    }

    _infoScrollTo() {
        this.carousel.addEventListener('click', () => {
            window.scrollTo({
                top: this.information.offsetTop,
                left: 0,
                behavior: 'smooth'
              });
        });
    }

    _roomsScrollTo() {
        this.seeRoomsBtns.forEach(btn => {
            btn.addEventListener('click', () => {
                window.scrollTo({
                    top: this.rooms.offsetTop,
                    left: 0,
                    behavior: 'smooth'
                });
            });
        })
    }

    /** ----------------------------------------
        Initialize
     ---------------------------------------- */

    init() {
        if(!this.wrapper) return;
        
        if(this.slider && !this.isTablet) {
            this._scroll();

            this.slider.slick({
                dots: false,
                infinite: false,
                speed: 700,
                slidesToShow: 1,
                fade: true,
                adaptiveHeight: false,
                autoplay: false,
                arrows: false,
            });
        }

        this._infoScrollTo();
        this._roomsScrollTo();
        this._getItemPos();
        this._checkFixed();
    }
}
