import { Helpers } from './Helpers.js';

/** ----------------------------------------
     Log
 ---------------------------------------- */

/**
 * The class will help you debug variables
 * and set styled messages to the console.
 */

export class Log extends Helpers {

    /**
     * @init            calls the init method
     */

    constructor(data) {
        super();
        this.data = data;
        this.types = [
            'array',
            'boolean',
            'number',
            'object',
            'string'
        ];
    }

    /** ----------------------------------------
        Check Type
     ---------------------------------------- */

    checkType(data, type) {
        let result,
            string,
            boolean,
            number,
            object,
            array = '';

        switch (type) {
        case 'array':
            array = this.isArray(data);

            result = {
                message: array ? 'success' : 'error',
            };

            break;

        case 'object':
            object = this.isObject(data);

            result = {
                message: object ? 'success' : 'error'
            };

            break;
        case 'string':
            string = this.isString(data);

            result = {
                message: string ? 'success' : 'error'
            };

            break;
        case 'boolean':
            boolean = this.isBoolean(data);

            result = {
                message: boolean ? 'success' : 'error'
            };

            break;
        case 'number':
            number = this.isNumber(data);

            result = {
                message: number ? 'success' : 'error'
            };

            break;
        default:
            this.log('Something went wrong', 'error');
        }

        result['payload'] = this.getType(data);
        return result;
    }

    /** ----------------------------------------
        Debug Object
     ---------------------------------------- */

    object(data) {
        for(let values of data) {
            if (data.hasOwnProperty(values)) {
                if (typeof data[values] == 'object') {
                    this.object(data[values] + '.' + values);
                } else {
                    console.log(values + '   ' + data[values]);
                }
            }
        }
    }

    /** ----------------------------------------
        Log Message
     ---------------------------------------- */

    log(message, style, data) {
        if(this.data.info.environment.live)
            return;

        style = style || 'color: #000';

        switch (style) {
        case 'error':
            style = 'color: #FF1313';
            break;

        case 'info':
            style = 'color: #341D86';
            break;

        case 'success':
            style = 'color: #70C354';
            break;

        case 'warning':
            style = 'color: #FFB413';
            break;

        default:
            console.log('No style provided');
        }

        if(this.isArray(message))
            message = message.join(' %c ');

        style = this.isArray(style) ? style : [style];

        console.log(`%c ${ message } `, ...style);

        if(data) console.log(data);
    }

    /** ----------------------------------------
        Title Element
     ---------------------------------------- */

    title(title, data) {
        /* eslint-disable */
        this.log(title,
            'border-radius: 3px;' +
            'padding: 0.25em 0; ' +
            'margin: 0.25em 0 0.35em; ' +
            'background: #000; ' +
            'font-size: 10px; ' +
            'color: #FFF;'
        , data);
        /* eslint-enable */
    }

    /** ----------------------------------------
         Section Element
     ---------------------------------------- */

    section(title, type, data) {
        this.log([title, type], [
            'border-top-left-radius: 3px;' +
            'border-bottom-left-radius: 3px;' +
            'padding: 0.25em 0; ' +
            'margin: 0.25em 0; ' +
            'background: #000000; ' +
            'font-size: 10px; ' +
            'color: #FFFFFF;',
            'border-top-right-radius: 3px;' +
            'border-bottom-right-radius: 3px;' +
            'padding: 0.25em 0; ' +
            'margin: 0.25em 0; ' +
            'background: #341D88; ' +
            'font-size: 10px; ' +
            'color: #96FFF5;',
        ], data);
    }

    /** ----------------------------------------
        Debug
     ---------------------------------------- */

    debug(input, expected) {
        let result = {},
            data = '';

        if(this.types.includes(expected)) {
            result = this.checkType(input, expected);
            data = input;
        } else if (input === expected) {
            result = {
                message: 'success',
                payload: true
            };
        } else {
            result = {
                message: 'error',
                payload: false
            };
        }

        this.title('Debug');

        this.log('input: ' + (!data ? input : expected), 'info', data);
        this.log('expected: ' + expected, 'info');
        this.log('result: ' + result.payload, result.message);
    }

}