/** ----------------------------------------
    Add Style
 ---------------------------------------- */

const style = (el, style) => {
    Object.keys(style).forEach(key => {
        el.style[key] = style[key];
    });
};

/** ----------------------------------------
    Add Unit
 ---------------------------------------- */

const unit = (value, unit = 'px') => {
    return value + unit;
};

/** ----------------------------------------
    Add Attribute
 ---------------------------------------- */

const attr = (el, attr) => {
    Object.keys(attr).forEach(key => {
        el.setAttribute(key, attr[key]);
    });
};

/** ----------------------------------------
 Exports
 ---------------------------------------- */

export {
    attr,
    style,
    unit
}