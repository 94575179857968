/** ----------------------------------------
    Environment
 ---------------------------------------- */

/**
 * This class will check the current
 * environment of the website and stores
 * an object with information about the
 * current environment.
 */

export class Environment {

    /**
     * @data            settings data
     * @url             stores the current url
     * @current         current environment
     * @path            current path
     * @info            stores information
     * @init            init class
     */

    constructor(data) {
        this.data = data;
        this.url = window.location.href;
        this.current = '';
        this.path = '';
        this.info = {};
        this.init();
    }

    /** ----------------------------------------
        Store Information
     ---------------------------------------- */

    setEnvironment() {
        this.info.environment = {};
        this.setPreview();
        this.setLocalhost();
        this.setLive();
        this.setWebpack();
        this.setBrowser();
        this.setMobile();
        this.setUserAgent();
    }

    /** ----------------------------------------
        Set Path Related
     ---------------------------------------- */

    setPreview() {
        this.info.environment['preview'] = this.url.includes('preview');
    }

    setLive() {
        this.info.environment['live'] = (!this.info.environment.localhost && !this.info.environment.preview);
    }

    setLocalhost() {
        this.info.environment['localhost'] = (this.url.includes('localhost') || this.url.includes('127.0.0.1') && !this.info.environment.preview);
    }

    setWebpack() {
        this.info['date'] = this.data.webpack.date;
        this.info['theme'] = this.data.webpack.themes;
        this.info['webpack'] = this.data.webpack.config;
        this.info['webpack'] = this.data.webpack.guide;
    }

    setPath() {
        let root = window.location.origin;
        let localName = this.data.localhost;
        let previewName = this.data.preview ? this.data.preview : this.data.localhost;

        if (previewName === 'undefined' ? (previewName == localName) : previewName);

        this.info.path = {
            root: root,
            localhost: root + '/' + localName + '/public',
            preview: root + '/' + previewName + '/public',
            live: root
        };
    }

    setBrowser() {
        /* eslint-disable */
        let browser = {
            opera: (!!window.opera && !!window.opera.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0,
            firefox: typeof InstallTrigger !== 'undefined',
            safari: /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === '[object SafariRemoteNotification]'; })(!window['safari'] || (typeof window.safari !== 'undefined' && window.safari.pushNotification)),
            ie: false || !!document.documentMode,
            edge: navigator.userAgent.indexOf('Edge') >= 0 && !!window.StyleMedia,
            chrome: !!window.chrome && !!window.chrome.webstore
        };
        /* eslint-enable */

        this.info.browser = browser;
    }

    setMobile() {
        let mobile = {
            android: navigator.userAgent.match(/Android/i),
            blackberry: navigator.userAgent.match(/BlackBerry/i),
            apple: navigator.userAgent.match(/iPhone|iPad|iPod/i),
            opera: navigator.userAgent.match(/Opera Mini/i),
            windows: navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i)
        };

        this.info.mobile = mobile;
    }

    setUserAgent() {
        this.info.userAgent = navigator.userAgent;
    }

    /** ----------------------------------------
        Set Language
     ---------------------------------------- */

    setLanguage() {
        let language = document.body.dataset.language;

        if(language)
            this.info.language = language;
    }

    translate(nl, eng) {
        if(eng == null || eng === false)
            return nl;
        return (this.info.language == 'nl' ? nl : eng);
    }

    /** ----------------------------------------
        Mobile Device
     ---------------------------------------- */

    isMobile() {
        let keys = Object.keys(this.info.mobile);
        let active = keys.filter(key => this.info.mobile[key] === true);
        return active.length > 0;
    }

    /** ----------------------------------------
        Current Environment
     ---------------------------------------- */

    setCurrent() {
        let environment = this.info.environment;

        for (let type in environment) {
            if(environment[type] === true) {
                this.current = type;
                this.path = this.info.path[type];
            }
        }
    }

    /** ----------------------------------------
        Log Results
     ---------------------------------------- */

    log() {
        console.log('The website is currently in: ' + this.current);
        console.log('The current root path is: ' + this.path);
        console.log(this.info);
    }

    /** ----------------------------------------
        Init
     ---------------------------------------- */

    /**
     * This function is called within the
     * constructor and will initiate once the
     * class is loaded.
     */

    init() {
        this.setEnvironment();
        this.setPath();
        this.setLanguage();
        this.setCurrent();
    }
}