import anime from 'animejs';

/** ----------------------------------------
    Label
 ---------------------------------------- */

export class Label {

    constructor(settings) {
        this._form = document.querySelector(settings.form);
        this._els = [];
        this.init();
    }

    /** ----------------------------------------
         Retrieve Inputs
     ---------------------------------------- */

    _inputs() {
        const labels = this._form.querySelectorAll('.label__input');

        [...labels].map(label => {
           this._els.push({
               label: label,
               input: label.nextSibling.nextSibling
           });
        });
    }

    /** ----------------------------------------
         Set Events
     ---------------------------------------- */

    _events() {
        this._els.map(el => {
            el.input.addEventListener('focus', () => {
                this._active(el.label);
            });

            el.input.addEventListener('focusout', () => {
                switch(el.input.type) {
                    // Webkits ::-webkit-datetime-edit-fields-wrapper bladiebla conflicts with this setup
                    // Set is-active on parent element, and forget about it to save time.
                    case "time":
                    case "date": return;
                    default:
                        return el.input.value.length === 0 && this._inActive(el.label)
                }
            });
        });
    }

    /** ----------------------------------------
         Modify DOM State
     ---------------------------------------- */

    _active(el) {
        el.classList.add('is-active');
    }

    _inActive(el) {
        el.classList.remove('is-active');
    }

    /** ----------------------------------------
        Init
     ---------------------------------------- */

    /**
     * This function is called within the
     * constructor and will initiate once the
     * class is loaded.
     */

    init() {
        if(!this._form) return;
        this._inputs();
        this._events();
    }

}