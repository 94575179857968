/**
 * Vruchtvlees © 2007-2019
 *
 * Hi, welcome!
 *
 * This is the main Javscript file.
 * One script to rule them all!
 * Let's go and godspeed you!
 */

import $ from 'jquery';

/** ----------------------------------------
    Webpack Settings
 ---------------------------------------- */

// eslint-disable-next-line
let webpack = SETTINGS;

/** ----------------------------------------
    Environment
 ---------------------------------------- */

/**
 * This class will check the current
 * environment of the website and stores
 * an object with information about the
 * current environment.
 */

import { Environment } from '@helper/Environment.js';
import { Log } from '@helper/Log.js';

const host = new Environment({
    webpack: webpack
});

const log = new Log(host);

if(!host.info.environment.live) {
    log.section('VV', 'Environment');
    host.log();
}

/** ----------------------------------------
    Themes Toggle
 ---------------------------------------- */

/**
 * This section will set a toggle within
 * the DOM to switch between multiple
 * themes, the class only initiates if there
 * is more than one available theme declared
 * within the Webpack settings.
 */

import { ThemesToggle } from '@js/style/Theme.js';
new ThemesToggle(webpack);

/** ----------------------------------------
    Set Style Guide
 ---------------------------------------- */

/**
 * This section will set the VV Style Guide
 * as a default for at the index.html.
 *
 * You can mess around with the information
 * it will be displayed within the page.
 */

import { Style } from '@js/style/Style.js';

let info = {
    title: 'Vruchtvlees Digital Style Guide',
    intro: 'This style guide provides guidelines for the digital design as configured within the SASS stylesheets. It will automatically extend and change once you start working on the project. If certain elements or blocks should be left out these can be configured within the config.js file related to the style guide. It serves as a guide to quickly check what variables have been configured, what names they use and how the styling of a certain element has been affected within the stylesheet. Godspeed you!',
};

let config = {
    breakpoints: true,
    grid: true,
    typo: true,
    letters: true,
    colors: true,
    headings: true,
    text: true,
    content: true,
    font: true,
    links: true,
    tooltip: true,
    forms: true,
    lists: true,
    tables: true,
    buttons: true,
    icons: true
};

const style = new Style(info, config, webpack.config.development && webpack.config.guide);

if(!host.info.environment.live) {
    log.section('VV', 'Style');
    style.log();
}

/** ----------------------------------------
     Menu
 ---------------------------------------- */

import { HeaderMarquee } from '@js/module/HeaderMarquee';
new HeaderMarquee();

/** ----------------------------------------
    More
 ---------------------------------------- */

import { More } from '@js/module/More';
const firstParagraph = document.querySelector('.intro__desc p:first-child');
if(firstParagraph) {
    new More({
        wrapper: '.intro',
        thresh: 500,
        height: firstParagraph.offsetTop + firstParagraph.clientHeight,
        content: {
            more: 'Meer lezen',
            less: 'Verbergen'
        }
    });
}

/** ----------------------------------------
     Header Marquee
 ---------------------------------------- */

import { MobileMenu } from '@js/module/MobileMenu';
new MobileMenu();

/** ----------------------------------------
    Makers
 ---------------------------------------- */

import { Makers } from '@js/module/Makers';

const makers = document.querySelectorAll('.makers');

[...makers].forEach(maker => {
    new Makers(maker);
});

/** ----------------------------------------
    Slider
 ---------------------------------------- */

import 'slick-carousel';

// Small tablet breakpoint
const tabletSmBp = 768;
const isTabletSm = window.innerWidth <= tabletSmBp;
// Large tablet breakpoint
const tabletLgBp = 1080;
const isTabletLg = window.innerWidth <= tabletLgBp;

window.addEventListener('load', () => {
    let $gallerySlider = $('.gallery-block__slides');

    if($gallerySlider) {
        $gallerySlider
        .each(aftermarketSliderArrows.bind(this, '.gallery-block__'))
        .slick({
            dots: false,
            infinite: isTabletSm ? true : false,
            speed: 300,
            fade: isTabletSm ? true : false,
            adaptiveHeight: false,
            autoplay: false,
            arrows: false,
            slidesToShow: isTabletSm ? 1 : 1.5,
            centerMode: false,
            variableWidth: false,
        })
    }

    let $highlightSlider = $('.highlight-block__slides');

    if($highlightSlider) {
        $highlightSlider
            .each(aftermarketSliderArrows.bind(this, '.highlight-block__'))
            .slick({
                dots: false,
                infinite: true,
                speed: 300,
                slidesToShow: 1,
                fade: true,
                adaptiveHeight: false,
                autoplay: true,
                autoplaySpeed: 1500,
                arrows: false,
            })
    }

    let $sliderBlock = $('.slider-block__slides');

    if($sliderBlock) {
        $sliderBlock
            .each(aftermarketSliderArrows.bind(this, '.slider-block__'))
            .slick({
                dots: false,
                infinite: true,
                speed: 300,
                slidesToShow: 1,
                fade: true,
                adaptiveHeight: true,
                autoplay: false,
                arrows: false,
            })
    }

    let $headerSlider = $('.header__slider');

    if($headerSlider) {
        $headerSlider
            .each(aftermarketSliderArrows.bind(this, '.header__slider-'))
            .slick({
                dots: false,
                infinite: true,
                speed: 300,
                slidesToShow: 1,
                fade: true,
                adaptiveHeight: false,
                autoplay: false,
                arrows: false,
            })
    }

    let $newsSlider = $('.news__slides');

    if($newsSlider) {
        const slidesToShow = Math.max(Math.floor($newsSlider.width() / 300), 1);
        $newsSlider
            .each(aftermarketSliderArrows.bind(this, '.news__arrows .news__'))
            .slick({
                dots: false,
                infinite: isTabletLg ? true : false,
                speed: 300,
                slidesToShow,
                fade: false,
                adaptiveHeight: slidesToShow > 1 ? false : true,
                autoplay: false,
                arrows: true,
                nextArrow: $('.news__arrow--right'),
                prevArrow: $('.news__arrow--left')
            })
    }

    let $roomsSlider = $('.rooms__slides');

    if($roomsSlider) {
        $roomsSlider
            .each(aftermarketSliderArrows.bind(this, '.rooms__'))
            .slick({
                dots: false,
                infinite: true,
                speed: 300,
                slidesToShow: 1,
                fade: true,
                adaptiveHeight: false,
                autoplay: false,
                arrows: false,
            })
    }

    function aftermarketSliderArrows(selector, index, elem) {
        const $slick = $(elem);
        
        const nextArrow = $(`${selector}arrow--right`, elem.parentNode) || $(`${selector}arrow--right`);
        const prevArrow = $(`${selector}arrow--left`, elem.parentNode) || $(`${selector}arrow--right`);
        
        nextArrow.on('click', () => $slick.slick('slickNext'))
        prevArrow.on('click', () => $slick.slick('slickPrev'))
        
        $slick.on('afterChange init', (event, slick, currentSlide) => {
            if(currentSlide === undefined) currentSlide = 0;
            nextArrow.toggleClass('slick-arrow slick-hidden', slick.slideCount <= 1 && currentSlide >= slick.slideCount - 1)
            prevArrow.toggleClass('slick-arrow slick-hidden', slick.slideCount <= 1 && currentSlide <= 0)
        })
    }
});

/** ----------------------------------------
    Label
 ---------------------------------------- */

import { Label } from '@js/module/Label';

new Label({
    form: '#information-form'
});

/** ----------------------------------------
    Dropdown
 ---------------------------------------- */

import { Dropdown } from './module/Dropdown';

const filters = document.querySelectorAll('.dropdown__options');

for (let filter of filters) {
    new Dropdown({
        holder: filter,
        trigger: '.dropdown__selected',
        target: '.dropdown__holder',
        options: '.dropdown__form'
    }, host);
}

/** ----------------------------------------
    Sticky
 ---------------------------------------- */

import { Sticky } from '@js/module/Sticky';
new Sticky('.sticky', isTabletSm);

/** ----------------------------------------
    Video
 ---------------------------------------- */

import Video from '@js/module/Video';

const videos = document.querySelectorAll('.media-block');

[...videos].forEach(video => {
   new Video(video);
});

/** ----------------------------------------
    FANCY ARROW
 ---------------------------------------- */

import FancyArrow from '@js/module/FancyArrow';
FancyArrow();

/** ----------------------------------------
    Scroll to form
 ---------------------------------------- */

let formBtns = document.querySelectorAll('.header__img-btn');
let formTarget = document.querySelector('.information')

formBtns.forEach(btn => {
    !formTarget && (btn.style.display = 'none');

    btn.addEventListener('click', (e) => {
        window.scrollTo({
            top: formTarget ? formTarget.offsetTop : 0,
            left: 0,
            behavior: 'smooth'
        });
    });
})


/** ----------------------------------------
    Social Share
 ---------------------------------------- */

 const share = document.querySelectorAll('[data-js-hook="share"]');
 if(share.length) {
    import('@js/module/SocialShare').then(({default: SocialShare}) => {
        share.forEach(container => {
            container.querySelectorAll('a').forEach(btn => {
                new SocialShare(btn);
            })
        })
    })
 }


 /** ----------------------------------------
    carousel
 ---------------------------------------- */
window.addEventListener('load', () => {
 const stickyCarousel = $('[data-js-hook="carousel"].carousel--sticky');
 if(stickyCarousel.length) {
     const vpHeight = window.innerHeight;
     let scrollStop;

     document.addEventListener('more:true', updateScrollStop);
     document.addEventListener('more:false', updateScrollStop);

     updateScrollStop();
     function updateScrollStop(e) {
        scrollStop = document.body.clientHeight - document.querySelector('footer').clientHeight;
     }

     document.addEventListener('scroll', () => {
         const bottom = vpHeight + window.scrollY;
         if(bottom > scrollStop) {
             stickyCarousel.css({
                top: scrollStop,
                position: 'absolute'
            })
         } else {
             stickyCarousel.removeAttr('style');
         }
     })
 }
})