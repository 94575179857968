/** ----------------------------------------
    Node Modules
 ---------------------------------------- */

import anime from 'animejs';

/** ----------------------------------------
    Fade Out
 ---------------------------------------- */

const fadeOut = el => {
    anime({
        targets: el,
        opacity: [1, 0],
        duration: 750,
        easing: 'easeOutQuad',
        complete: () => el.remove()
    })
};

/** ----------------------------------------
    Video
 ---------------------------------------- */

export default class Video {

    constructor(video) {
        this.video = video;
        this.play = {};
        this.placeholder = {};
        this.init();
    }

    /** ----------------------------------------
        Click Event
     ---------------------------------------- */

    _click() {
        this.play.addEventListener('click', () => {
            fadeOut(this.placeholder);
        });
    }

    /** ----------------------------------------
        Init
     ---------------------------------------- */

    init() {
        this.play = this.video.querySelector('.media-block__button');
        this.placeholder = this.video.querySelector('.media-block__placeholder');

        this._click();
    }
}
