import anime from 'animejs';

/** ----------------------------------------
    Header Marquee
 ---------------------------------------- */

/**
 * The accordion class will take a
 * question and an answer class.
 * Modify at your hearts content.
 */

export class HeaderMarquee {

    /**
     * @accordion       accordion contained
     * @answer          the awnser class
     * @question        the question class
     * @init            calls the init method
     */

    constructor() {
        this.els = {};
        this.switched = false;
        this.init();
    }

    /** ----------------------------------------
        Elements
     ---------------------------------------- */

    _els() {
        const header = document.querySelector('.header--home');

        if(header) {
            const payoff = header.querySelector('.header__payoff');
            const holder = header.querySelector('.header__values');
            const values = header.querySelectorAll('.header__value');
            this.els = {header, holder, payoff, values};
        }

        return header;
    }

    /** ----------------------------------------
        Font Size
     ---------------------------------------- */

    _emSize() {
        return parseFloat(getComputedStyle(this.els.payoff).fontSize);
    }

    /** ----------------------------------------
        Animate
     ---------------------------------------- */

    _animate() {
        [...this.els.values].forEach(value => {
            anime({
                targets: value,
                translateY: '-100%',
                easing: 'easeOutQuad',
                delay: 2500,
                duration: 500,
                complete: () => !this.switched && this._switch()
            })
        });
    }

    /** ----------------------------------------
         Switch
     ---------------------------------------- */

    _switch() {
        this.switched = true;

        setTimeout(() => {
            this._els();
            const item = this.els.values[0];
            const clone = item.cloneNode(true);
            this.els.holder.appendChild(clone);

            this._reset();
            item.remove();
            this.switched = false;
        }, 500);
    }

    /** ----------------------------------------
         Reset
     ---------------------------------------- */

    _reset() {
        const reset = item => item.removeAttribute('style');
        [...this.els.values].forEach(reset);

        this._setValues();
        this._animate();
    }

    /** ----------------------------------------
        Set Values
     ---------------------------------------- */

    _setValues() {
        this.els.values = this.els.header.querySelectorAll('.header__value');
    }

    /** ----------------------------------------
        Init
     ---------------------------------------- */

    init() {
        const el = this._els();

        if(el) {
            this._emSize();
            this._animate();
        }
    }

}