import anime from 'animejs';

/* custom helpers */
import { triggerSelectEvent } from './../helpers/Helpers.js';

/** ----------------------------------------
    Dropdown
 ---------------------------------------- */

/**
 * This class will initiate the dropdown
 * open and close animation.
 */

export class Dropdown {

    /**
     * @holder          dropdown contained
     * @trigger         the trigger class
     * @target          the target class
     * @init            calls the init method
     */

    constructor(settings, host) {
        this.element = '';
        this.holder = settings.holder;
        this.options = this.holder.querySelector(settings.options);
        this.trigger = this.holder.querySelector(settings.trigger);
        this.target = this.holder.querySelector(settings.target);
        this.mobile = false;
        this.host = host;
        this.handleEvent = this.handleEvent.bind(this);

        if (!this.holder || !this.options || !this.trigger || !this.target)
            return;

        this.init();
    }

    /** ----------------------------------------
        Helper Function
     ---------------------------------------- */

    isDescendant(parent, child) {
        let node = child.parentNode;

        while (node != null) {
            if (node === parent)
                return true;

            node = node.parentNode;
        }

        return false;
    }

    /** ----------------------------------------
        Delegate
     ---------------------------------------- */

    delegate() {
        return [...this.options.querySelectorAll('input')].forEach(input => {
            input.addEventListener('change', this.handle.bind(this));
        });
    }

    handle() {
        let type = this.inputType();

        if(type === 'radio')
            this.handleRadio();

        if(type === 'checkbox')
            this.handleCheckbox();
    }

    /** ----------------------------------------
        Delegate Helpers
     ---------------------------------------- */

    inputType() {
        let elements = this.options.querySelectorAll('input');
        return elements[0].type;
    }

    checkedInput() {
        return [...this.options.querySelectorAll('input')].filter(input => input.checked);
    }

    /** ----------------------------------------
        Handle Delegate
     ---------------------------------------- */

    handleCheckbox() {
        let checked = this.checkedInput();

        if(checked.length) {
            this.trigger.innerHTML = checked.map(inp => inp.value).join(", ");
        }
        this.checkState();
    }

    handleRadio() {
        let checked = this.checkedInput();

        if (checked[0]) {
            let value = checked[0].parentElement.getElementsByTagName('label');
            this.trigger.innerHTML = value[0].innerText;
        }

        this.toggleState();
        this.checkState();
    }

    /** ----------------------------------------
        Set Dropdown
     ---------------------------------------- */

    /**
     * Will find a given element from the
     * settings and watches for a click event.
     */

    setHolder() {
        this.trigger.addEventListener('click', (e) => {
            if(e.target.classList.contains('is-animating'))
                return;

            this.toggleState();
            this.checkState();
        });

        this.delegate();
    }

    /** ----------------------------------------
        Dropdown Event
     ---------------------------------------- */

    listenOutsideEvent() {
        document.addEventListener('click', this.handleEvent, false);
    }

    removeOutsideEvent() {
        document.removeEventListener('click', this.handleEvent, false);
    }

    handleEvent() {
        if(!this.element)
            this.element = event.srcElement;

        if (!this.isDescendant(this.element.nextElementSibling, event.srcElement) && event.srcElement !== this.element) {
            this.element.classList.remove('is-active');
            this.close(this.element);
        } 

        return this;
    }

    /** ----------------------------------------
        Toggle State Classes
     ---------------------------------------- */

    toggleState() {
        this.trigger.classList.toggle('is-active');
        this.trigger.classList.toggle('is-closed');
    }

    /** ----------------------------------------
        Check Current State Class
     ---------------------------------------- */

    /**
     * Check state of clicked item and delegate
     * an open or close event.
     */

    setActive() {
        this.trigger.classList.toggle('is-animating');
    }

    checkState() {
        this.trigger.classList.contains('is-active') ? this.open() : this.close();
    }

    /** ----------------------------------------
        Open Dropdown
     ---------------------------------------- */

    /**
     * Find height of answer and set it to the
     * parent holder element with a simple
     * animation.
     */

    open() {
        if(this.mobile)
            return;

        let height = this.options.offsetHeight;

        this.animateOpen(height);
        this.setActive();
        this.listenOutsideEvent();
    }

    animateOpen(height) {
        height = height > 320 ? 320 : height;

        let open = anime({
            targets: this.target,
            height: height,
            duration: 200,
            easing: 'easeOutQuad'
        });

        open.finished.then(() => {
            this.target.style.height = 'auto';
            this.setActive(this.trigger);
        });
    }

    /** ----------------------------------------
        Close Dropdown
     ---------------------------------------- */

    /**
     * Reset the height of the holder back to
     * zero with a simple animation.
     */

    close() {
        if(this.mobile)
            return;

        this.animateClose();
        this.setActive();

        this.element = '';
        this.removeOutsideEvent();
    }

    animateClose() {
        let close = anime({
            targets: this.target,
            height: 0,
            duration: 350,
            easing: 'easeOutQuad'
        });

        close.finished.then(() => {
            this.setActive(this.trigger);
        });
    }

    /** ----------------------------------------
        Mobile
     ---------------------------------------- */

    checkMobile() {
        if(this.host.isMobile()) this.mobile = true;
        if(this.mobile) this.handleMobile();
    }

    handleMobile() {
        let type = this.inputType();
        this.setOptions(type);
    }

    hideOption(el) {
        el.style.position = 'absolute';
        el.style.left = -9999;
    }

    setOptions(type) {
        let options = this.options.querySelectorAll('input');
        let select = document.createElement('select');
        if (type === 'checkbox') select.multiple = true;
        this.hideOption(select);
        this.holder.append(select);

        [...options].map(option => {
            let id = option.id;
            let value = option.nextElementSibling.innerHTML;
            let element = this.createOption(value, id);
            select.append(element);
        });

        this.mobileEvent(select);
    }

    createOption(value, id) {
        let option = document.createElement('option');
        option.text = value;
        option.value = id;
        return option;
    }

    mobileEvent(select) {
        this.trigger.addEventListener('click', (e) => {
            triggerSelectEvent(select);
        });

        select.addEventListener('change', (e) => {
            this.selectedReset();
            let options = e.target.getElementsByTagName('option');
            let selected = [...options].filter(option => option.selected === true);
            [...selected].map(select => this.selectedMobile(select));
            this.handle();
        });
    }

    selectedReset() {
        let inputs = this.options.getElementsByTagName('input');
        [...inputs].map(input => input.checked = false);
    }

    selectedMobile(select) {
        let inputs = this.options.getElementsByTagName('input');

        [...inputs].map(input => {
            if(input.id === select.value)
                input.checked = true;
        });
    }

    /** ----------------------------------------
        Init
     ---------------------------------------- */

    /**
     * This function is called within the
     * constructor and will initiate once the
     * class is loaded.
     */

    init() {
        // this.checkMobile();
        this.setHolder();
    }

}