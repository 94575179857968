/** ----------------------------------------
    Configure Layout
 ---------------------------------------- */

/**
 * These functions can be added or removed
 * to add specific elements to the style
 * guide such as color palettes and text
 * styles. There a some options that can
 * be declared depending on the type of
 * template that is used.
 */

export class StyleConfig {

    /** ----------------------------------------
        Default Configure
     ---------------------------------------- */

    /**
     * The order of these functions is linked
     * to the actual style guide. A logo and
     * intro text is presented by default within
     * the setContent() method.
     */

    configTemplates() {
        this.settings.breakpoints && this.configBreakpoints();
        this.settings.grid && this.configGrid();
        this.settings.typo && this.configTypography();
        this.settings.letters && this.configLetters();
        this.settings.colors && this.configColors();
        this.settings.headings && this.configHeadings();
        this.settings.text && this.configText();
        this.settings.content && this.configContent();
        this.settings.font && this.configTextStyles();
        this.settings.links && this.configLinks();
        this.settings.tooltip && this.configTooltip();
        this.settings.forms && this.configForms();
        this.settings.lists && this.configLists();
        this.settings.tables && this.configTables();
        this.settings.buttons && this.configButtons();
        this.settings.buttons && this.configIcons();
    }

    /** ----------------------------------------
        Configure Breakpoints
     ---------------------------------------- */

    configBreakpoints() {
        this.addTemplate('title', {
            title: 'Breakpoints'
        });

        this.addTemplate('breakpoints', {
            title: 'Breakpoints',
            desc: 'These are the breakpoint values that are used within the media query.'
        }, this.data.breakpoints);

        this.addTemplate('separator');
    }

    /** ----------------------------------------
        Configure Grid
     ---------------------------------------- */

    configGrid() {
        this.addTemplate('title', {
            title: 'Grid',
            desc: 'These examples provide an overview of the grid layout as configured. These can only be viewed in a desktop view.'
        });

        this.addTemplate('grid');

        this.addTemplate('separator');
    }

    /** ----------------------------------------
        Configure Typography
     ---------------------------------------- */

    configTypography() {
        this.addTemplate('title', {
            title: 'Typography'
        });

        this.addTemplate('font-face', {
            title: 'Primary',
            desc: 'These are the main custom fonts declared within the stylesheet.'
        }, this.data.font_face.custom);

        this.addTemplate('fonts', {
            title: 'Fallback',
            desc: 'The fallback fonts are declared as a backup font when the primary fonts fail to load.'
        }, this.data.font_face.fallbacks);

        this.addTemplate('separator');
    }

    /** ----------------------------------------
        Configure Letters
     ---------------------------------------- */

    configLetters() {
        /** letters **/
        this.addTemplate('title', {
            title: 'Letters'
        });

        this.addTemplate('letters', {
            title: 'Alphabet',
            desc: 'These examples show a quick overview of the all main characters used within the font. Make sure all characters are accounted for.'
        }, this.data.font_face.all);

        this.addTemplate('separator');
    }

    /** ----------------------------------------
        Configure Colors
     ---------------------------------------- */

    configColors() {
        this.addTemplate('title', {
            title: 'Colors'
        });

        this.addTemplate('colors', {
            title: 'Palette',
            desc: 'These are the main colors used within the color palette of the website.'
        }, this.data.colors);

        this.addTemplate('combinations', {
            title: 'Combinations',
            desc: 'Combinations can be used with special mixins and functions for quick color combinations.'
        }, this.data.combinations);

        this.addTemplate('branding', {
            title: 'Branding',
            desc: 'Brand related colors are provided in a special map and have selected names such as light, dark or default. The names and '
        }, this.data.branding);

        this.addTemplate('separator');
    }

    /** ----------------------------------------
        Configure Headings
     ---------------------------------------- */

    configHeadings() {
        /** headings **/
        this.addTemplate('title', {
            title: 'Headings'
        });

        this.addTemplate('headings', {
            title: 'Global Heading',
            desc: 'These are the headings that are declared global from the root of the document.',
        });

        this.addTemplate('headings', {
            title: 'Heading Combination',
            desc: 'This example shows the contrast of headings applied to a color combination.',
            combi: true
        });

        this.addTemplate('headings', {
            title: 'Heading Article',
            desc: 'These headings are applied within the article tag witch contains the main content, such as the blocks, within the page.',
            article: true
        });

        this.addTemplate('separator');
    }

    /** ----------------------------------------
        Configure Text
     ---------------------------------------- */

    configText(){
        this.addTemplate('title', {
            title: 'Text'
        });

        this.addTemplate('text', {
            title: 'Text Default',
            article: true
        });

        this.addTemplate('text', {
            title: 'Text Combination',
            combi: true,
            article: true
        });
    }

    /** ----------------------------------------
        Configure Content
     ---------------------------------------- */

    configContent(){
        this.addTemplate('title', {
            title: 'Content',
            desc: 'These examples provide a piece of the tags that can be applied within the WYSIWYG Editor within the VV CMS. These examples show how the tags will be contained within a block of content.'
        });

        this.addTemplate('article');

        this.addTemplate('article', {
            combi: true
        });
    }

    /** ----------------------------------------
        Configure Text
     ---------------------------------------- */

    configTextStyles() {
        this.addTemplate('title', {
            title: 'Text Styles'
        });

        this.addTemplate('text-style');

        this.addTemplate('separator');
    }

    /** ----------------------------------------
        Configure Links
     ---------------------------------------- */

    configLinks() {
        this.addTemplate('title', {
            title: 'Links'
        });

        this.addTemplate('links', {
            title: 'Anchors',
            desc: 'There are different types of links. These show the main boilerplate anchors used within the project.'
        });

        this.addTemplate('separator');
    }

    /** ----------------------------------------
        Configure Tooltip
     ---------------------------------------- */

    configTooltip() {
        this.addTemplate('title', {
            title: 'Tooltips'
        });

        this.addTemplate('tooltip', {
            title: 'Tooltip',
            desc: 'Tooltips can be quiet handy when it comes to disclosing small chunks of information. You can use them both within an element or inline, use the .tooltip__holder--inline class to display a tooltip inline.'
        });

        this.addTemplate('separator');
    }

    /** ----------------------------------------
        Configure Forms
     ---------------------------------------- */

    configForms() {
        this.addTemplate('title', {
            title: 'Forms'
        });

        this.addTemplate('form-label', {
            title: 'Form Labels',
            desc: 'This example shows a form with the use of labels.',
            article: true
        });

        this.addTemplate('form-placeholder', {
            title: 'Form Placeholder',
            desc: 'This example shows a form with the use of placeholders.',
            article: true
        });

        this.addTemplate('form-options', {
            title: 'Form Options',
            desc: 'This example shows the main form selectors and options.',
            article: true
        });

        this.addTemplate('custom-form-options', {
            title: 'Form Options',
            desc: 'This example shows the main form selectors and options.',
            article: true
        });

        this.addTemplate('separator');
    }

    /** ----------------------------------------
        Configure Lists
     ---------------------------------------- */

    configLists() {
        this.addTemplate('title', {
            title: 'Lists'
        });

        this.addTemplate('ul-list', {
            title: 'Unsorted List',
            article: true
        });

        this.addTemplate('ol-list', {
            title: 'Ordered List',
            article: true
        });

        this.addTemplate('dd-list', {
            title: 'Discription List',
            article: true
        });

        this.addTemplate('separator');
    }

    /** ----------------------------------------
        Configure Tables
     ---------------------------------------- */

    configTables() {
        this.addTemplate('title', {
            title: 'Tables'
        });

        this.addTemplate('table-full', {
            title: 'Tables Full',
            article: true
        });

        this.addTemplate('table-plain', {
            title: 'Tables Plain',
            article: true
        });

        this.addTemplate('separator');
    }

    /** ----------------------------------------
        Configure Buttons
     ---------------------------------------- */

    configButtons() {
        this.addTemplate('title', {
            title: 'Buttons'
        });

        this.addTemplate('button-colors', {
            title: 'Button Colors'
        }, this.data.colors);

        this.addTemplate('button-sizes', {
            title: 'Button Size'
        }, this.data.colors);

        this.addTemplate('separator');
    }

    /** ----------------------------------------
        Configure Icons
     ---------------------------------------- */

    configIcons() {
        this.addTemplate('title', {
            title: 'Icons'
        });

        this.addTemplate('icons', {
            title: 'Icons',
            desc: 'These icons are declared within the configuration of this project.'
        }, this.data.icons, false);

        this.addTemplate('icons', {
            title: 'Icon Colors',
            desc: 'Beware! This technique is experimental. By default this CSS won\'t be build running npm run build.',
            color: true
        }, this.data.icons);

        this.addTemplate('separator');
    }

    /** ----------------------------------------
        Set Content
     ---------------------------------------- */

    /**
     * These are the default blocks to be
     * displayed when the style guide is
     * active within the page.
     */

    setContent() {
        this.setLogo();
        this.setIntro(this.info);
    }

}